@import "open-props/open-props.min.css";

/* Uncomment this if you want addiitonal "default" styles applied: */
/* @import "open-props/normalize.min.css"; */

:root {
  /* Define additional variables here. */

  /* You can use variables from Open Props in your own custom properties. */
  --title-font: "Domine";
  --body-font: "Inter";
  --color-primary: var(--purple-7);
  --color-muted: var(--gray-7);
  --color-prose: var(--gray-12);
  --color-h2: var(--purple-7);
  --color-h2-a: var(--violet-7);
  --color-h3: var(--violet-7);
  --color-background: var(--gray-0);
  --highlighter-color: var(--violet-1);
  --gradient-primary: linear-gradient(215deg, var(--violet-6) 0%, var(--violet-8) 35%, var(--purple-7) 100%);
  --gradient-primary-reverse: linear-gradient(55deg, var(--violet-6) 0%, var(--violet-8) 35%, var(--purple-7) 100%);
  --gradient-secondary: linear-gradient(190deg, var(--violet-1) 0%, var(--violet-0) 35%, var(--purple-0) 100%);
  --gradient-secondary-reverse: linear-gradient(320deg, var(--violet-1) 0%, var(--violet-0) 35%, var(--purple-0) 100%);
  --gradient-footer-bg: var(--gradient-secondary-reverse);
  --gradient-footer-fg: var(--gradient-primary);
  --gradient-3: linear-gradient(190deg, var(--violet-3) 0%, var(--violet-2) 35%, var(--purple-2) 100%);
  --gradient-4: linear-gradient(190deg, var(--violet-2) 0%, var(--violet-1) 35%, var(--purple-1) 100%);
  --content-width: min(65ch, 90%);
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-background: var(--gray-12);
    --color-prose: var(--gray-3);
    --color-muted: var(--gray-6);
    --color-primary: var(--violet-4);
    --gradient-primary: linear-gradient(215deg, var(--purple-4) 0%, var(--purple-3) 35%, var(--violet-3) 100%);
    --gradient-footer-bg: linear-gradient(190deg, var(--violet-12) 0%, var(--violet-10) 75%, var(--purple-11) 100%);
    --gradient-footer-bg: var(--gray-8);
    --gradient-footer-fg: linear-gradient(215deg, var(--purple-4) 0%, var(--purple-2) 55%, var(--violet-2) 100%);
    --gradient-secondary: var(--gray-8);
    --color-h2: var(--purple-4);
    --color-h2-a: var(--violet-4);
    --color-h3: var(--violet-4);
  }
}
