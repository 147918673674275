@import url('https://unpkg.com/@catppuccin/palette/css/catppuccin.css');

/* 
  Syntax Highlighting for Code Snippets

  https://www.bridgetownrb.com/docs/liquid/tags#stylesheets-for-syntax-highlighting

  Other styles available eg. https://github.com/jwarby/jekyll-pygments-themes
  To use another style, delete all styles in this file and replace them with
  the new styles. Or create your own!

*/

div.highlighter-rouge {
  /* border-radius: var(--radius-3); */
  background-color: #24273A;
  overflow-y: hidden;
  margin-inline: -1rem;
  /* padding-inline: 1rem; */
  /* margin-inline: -3rem; */
  /* padding-inline: 1.25rem; */
}


pre.highlight {
  /* margin-inline: -1rem; */
  padding-inline: 1rem;
  margin: 0;
  /* padding-inline: var(--size-4); */
  padding-block: var(--size-3);
  color: var(--gray-0);
  scrollbar-color: transparent;
  scrollbar-width: none;
  overflow: auto;
}

.highlight .hll { background-color: #ffffcc }
.highlight .p { color: var(--gray-0) }
.highlight .c { color: var(--ctp-macchiato-overlay0); font-style: italic } /* Comment */
.highlight .err { color: var(--ctp-macchiato-red) } /* Error */
.highlight .k { color: var(--ctp-macchiato-mauve); font-weight: bold } /* Keyword */
.highlight .o { color: var(--ctp-macchiato-sky); font-weight: bold } /* Operator */
.highlight .cm { color: var(--ctp-macchiato-overlay0); font-style: italic } /* Comment.Multiline */
.highlight .cp { color: var(--ctp-macchiato-overlay0); font-weight: bold; font-style: italic } /* Comment.Preproc */
.highlight .c1 { color: var(--ctp-macchiato-overlay0); font-style: italic } /* Comment.Single */
.highlight .cs { color: var(--ctp-macchiato-pink); font-weight: bold; font-style: italic } /* Comment.Special */
.highlight .gd { color: var(--gray-0); background-color: #ffdddd } /* Generic.Deleted */
.highlight .ge { color: var(--gray-0); font-style: italic } /* Generic.Emph */
.highlight .gr { color: var(--ctp--macchiato-red) } /* Generic.Error */
.highlight .gh { color: #999999 } /* Generic.Heading */
.highlight .gi { color: var(--gray-0); background-color: #ddffdd } /* Generic.Inserted */
.highlight .go { color: #888888 } /* Generic.Output */
.highlight .gp { color: #555555 } /* Generic.Prompt */
.highlight .gs { font-weight: bold } /* Generic.Strong */
.highlight .gu { color: #aaaaaa } /* Generic.Subheading */
.highlight .gt { color: #aa0000 } /* Generic.Traceback */
.highlight .kc { color: var(--ctp-macchiato-peach); font-weight: bold } /* Keyword.Constant */
.highlight .kd { color: var(--ctp-macchiato-mauve); font-weight: bold } /* Keyword.Declaration */
.highlight .kn { color: var(--ctp-macchiato-mauve); font-weight: bold } /* Keyword.Namespace */
.highlight .kp { color: var(--gray-0); font-weight: bold } /* Keyword.Pseudo */
.highlight .kr { color: var(--gray-0); font-weight: bold } /* Keyword.Reserved */
.highlight .kt { color: var(--ctp-macchiato-yellow); font-weight: bold } /* Keyword.Type */
.highlight .m { color: var(--ctp-macchiato-peach) } /* Literal.Number */
.highlight .s { color: var(--ctp-macchiato-green) } /* Literal.String */
.highlight .na { color: var(--ctp-macchiato-pink) } /* Name.Attribute */
.highlight .nb { color: var(--ctp-macchiato-sky) } /* Name.Builtin */
.highlight .nc { color: var(--ctp-macchiato-yellow); font-weight: bold } /* Name.Class */
.highlight .no { color: var(--ctp-macchiato-yellow); font-weight: bold } /* Name.Constant */
.highlight .nd { color: var(--ctp-macchiato-yellow); font-weight: bold } /* Name.Decorator */
.highlight .ni { color: var(--ctp-macchiato-yellow) } /* Name.Entity */
.highlight .ne { color: var(--ctp-macchiato-yellow); font-weight: bold } /* Name.Exception */
.highlight .nf { color: var(--ctp-macchiato-blue); } /* Name.Function */
.highlight .n { color: var(--ctp-macchiato-blue); } /* Name.Function */
.highlight .nl { color: var(--ctp-macchiato-sapphire); font-weight: bold } /* Name.Label */
.highlight .nn { color: var(--ctp-macchiato-yellow) } /* Name.Namespace */
.highlight .nt { color: var(--ctp-macchiato-lavendar) } /* Name.Tag */
.highlight .nv { color: var(--ctp-macchiato-flamingo) } /* Name.Variable */
.highlight .ow { color: var(--ctp-macchiato-sky); font-weight: bold } /* Operator.Word */
.highlight .w { color: #bbbbbb } /* Text.Whitespace */
.highlight .mf { color: var(--ctp-macchiato-peach) } /* Literal.Number.Float */
.highlight .mh { color: var(--ctp-macchiato-peach) } /* Literal.Number.Hex */
.highlight .mi { color: var(--ctp-macchiato-peach) } /* Literal.Number.Integer */
.highlight .mo { color: var(--ctp-macchiato-peach) } /* Literal.Number.Oct */
.highlight .sb { color: var(--ctp-macchiato-green) } /* Literal.String.Backtick */
.highlight .sc { color: var(--ctp-macchiato-green) } /* Literal.String.Char */
.highlight .sd { color: var(--ctp-macchiato-green) } /* Literal.String.Doc */
.highlight .s2 { color: var(--ctp-macchiato-green) } /* Literal.String.Double */
.highlight .se { color: var(--ctp-macchiato-green) } /* Literal.String.Escape */
.highlight .sh { color: var(--ctp-macchiato-green) } /* Literal.String.Heredoc */
.highlight .si { color: var(--ctp-macchiato-green) } /* Literal.String.Interpol */
.highlight .sx { color: var(--ctp-macchiato-green) } /* Literal.String.Other */
.highlight .sr { color: var(--ctp-macchiato-green) } /* Literal.String.Regex */
.highlight .s1 { color: var(--ctp-macchiato-green) } /* Literal.String.Single */
.highlight .ss { color: var(--ctp-macchiato-pink) } /* Literal.String.Symbol */
.highlight .bp { color: #999999 } /* Name.Builtin.Pseudo */
.highlight .vc { color: var(--ctp-macchiato-sky) } /* Name.Variable.Class */
.highlight .vg { color: var(--ctp-macchiato-sky) } /* Name.Variable.Global */
.highlight .vi { color: var(--ctp-macchiato-sky) } /* Name.Variable.Instance */
.highlight .il { color: var(--ctp-macchiato-peach) } /* Literal.Number.Integer.Long */

@media (width >= 95ch) {
  div.highlighter-rouge {
    border-radius: var(--radius-3);
  }
  pre.highlight {
    /* margin-inline: -1rem; */
    padding-inline: 1.5rem;
  }
}
