@import "variables.css";
@import "reset.css";
@import "elements.css";
@import "composition.css";
@import "utilities.css";

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Domine:wght@400;500;600;700&family=Satisfy&display=swap');

footer {
  background: var(--gradient-footer-bg);
  width: 100%;
  margin-block-start: var(--size-10);
  
  & .footer-flow {
    margin: 0 auto;
    width: var(--content-width);
    display: grid;
    grid-template-columns: [footer-start] repeat(8, 1fr) [footer-end];
    gap: min(1rem, 1%);
    grid-template-rows: repeat(2, fit-content);

    & .site-links {
      display: grid;
      list-style-type: none;
      grid-column: 1/4;
      text-align: left;
      margin-block-start: var(--size-5);
    }

    & .social-links {
      display: grid;
      list-style-type: none;
      grid-column: 5/9;
      text-align: right;
      justify-items: right;
      margin-block-start: var(--size-5);
    }

    & .site-links > a, & .social-links > a {
      background: var(--gradient-footer-fg);
      background-clip: text;
      width: fit-content;
    }


    @media (width >= 500px) {
      & .site-links {
        display: grid;
        list-style-type: none;
        grid-template-columns: subgrid;
        grid-template-rows: 1fr;
        grid-column: 1/4;
        text-align: center;
        margin-block-start: var(--size-5);
      }

      & .social-links {
        display: grid;
        grid-template-columns: subgrid;
        grid-template-rows: 1fr;
        grid-column: 6/9;
        justify-items: center;
        margin-block-start: var(--size-5);
      }
    }
  }
}

.attribution {
  margin-block: var(--size-7) var(--size-5);
  text-align: center;
  color: transparent;
  font-size: var(--font-size-1);
  background: var(--gradient-footer-fg);
  margin-inline: auto;
  width: auto;
  background-clip: text;
  grid-row: 2/3;
  grid-column: footer;
}

ul.blog-list {
  display: grid;
  grid-template-columns: [title-start] 1fr [title-end date-start] max-content [ date-end ];
  justify-items: stretch;
  padding-left: 0;

  & li {
    list-style-type: none;
    display: grid;
    grid-template-columns: subgrid;
    grid-column: title / date;
    margin-block: var(--size-1);

    & > .post-link {
      display: inline;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      grid-column: title;
      max-width: fit-content;
    }

    & > .publish-date {
      grid-column: date;
      justify-self: right;
    }
  }
}

/*  blocks  */
.back-link {
  display: block;
  width: fit-content;
  font-size: var(--font-size-3);
  /* margin-block-end: var(--size-4); */

  & .fa {
    margin-right: var(--size-2);
  }
}

.site-links, .social-links {
  display: flex;
}

.site-links > *, .social-links > * {
  font-size: var(--font-size-2);
}

.site-links > .fa-solid, .social-links > .fa-solid {
  color: var(--color-primary);
  font-size: .25rem;
  align-self: center;
  margin-inline: var(--size-2);
}

.site-links > a:not(:last-child)::after, .social-links > a:not(:last-child)::after {
  margin-inline: var(--size-2);
}

.publish-date {
  color: var(--color-muted);
  font-size: .825rem;
}

.footer-nav {
  justify-content: space-around;
}

.back-navigation {
  justify-content: center;
  text-align: center;
  font-weight: var(--font-weight-6);
  font-size: var(--font-size-1);
  margin-top: 1rem;
  margin-inline: 2rem;
}

.button-primary {
  background: var(--gradient-primary-reverse);
  color: white;
  border: 0;
  border-radius: 3rem;
  padding-block: .25rem;
  padding-inline: 1rem;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}
