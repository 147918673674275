.margin-bottom-2 {
  margin-bottom: 2rem;
}

.margin-bottom-4 {
  margin-bottom: var(--size-4);
}

.margin-top-0-5 {
  margin-top: 0.5rem;
}

.margin-top-2 {
  margin-top: 2rem;
}

.margin-top-3 {
  margin-top: 3rem;
}

.margin-top-4 {
  margin-top: 4rem;
}

.padding-left-0 {
  padding-left: 0;
}

.text-green, .text-violet {
  color: var(--violet-7);
}

.text-primary {
  color: var(--color-primary);
}

.text-dark-green {
  color: var(--violet-9);
}

.text-black {
  color: black;
}

.text-small {
  font-size: var(--font-size-0);
}

.truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.text-center {
  text-align: center;
}

.no-bullets {
  list-style-type: none;
}

.flex-row {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}
