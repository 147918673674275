body {
  font-family: var(--body-font), sans-serif;
  background: var(--color-background);
  color: var(--color-prose);
  min-height: 100svh;
  display: flex;
  flex-direction: column;
}

h2, h3 {
  font-family: var(--title-font), sans-serif;
  font-weight: 400;
}

h1 {
  font-family: var(--title-font), sans-serif;
  font-weight: 400;

  color: transparent;
  background: var(--gradient-primary);
  background-clip: text;
}

h2 {
  color: var(--color-h2);

  & a {
    color: var(--color-h2-a);
  }
  & a:hover {
    color: var(--color-h2);
  }
}

h3 {
  color: var(--color-h3);
}

hr {
  border: none;
  height: 1px;
  background: var(--gradient-primary);
}

figure {
  /* margin-inline-stt: -2rem; */
  & img {
    width: 40%;
    margin-inline: auto;
  }

  & figcaption {
    font-size: var(--font-size-0);
    width: 75%;
    margin-inline: auto;
    text-align: center;
    font-style: italic;
    margin-block-start: 1rem;
    color: var(--color-muted);
  }
}

header {
  margin-block: var(--size-7) var(--size-3);
  text-align: center;

  & a {
    font-family: var(--title-font);
  }
}

main {
  max-width: min(65ch, 90%);
  width: 100%;
  margin: 0 auto;
  margin-top: var(--size-12);
  flex-grow: 1;
}

label {
  font-weight: var(--font-weight-5);
}

ul {
  padding-left: var(--size-4);
}

p > code {
  color: var(--color-primary);
  font-weight: bold;
  font-size: 1rem;
}

nav {
  display: flex;
  flex-direction: row;
  font-weight: var(--font-weight-5);

  & > ul {
    width: 100%;
    display: flex;
    flex-direction: row;
    list-style-type: none;
    padding-left: 0;

    & > * {
      margin-right: 1rem;
    }
  }
}

a {
  color: var(--color-primary);
  text-decoration: none;
  color: transparent;
  background: var(--gradient-primary);
  background-clip: text;
}

p > a {
  color: transparent;
  background: var(--gradient-primary);
  background-clip: text;
  font-weight: 500;
}

a:hover {
    color: var(--purple-7);
  }

p {
  margin: 1rem 0;
}

pre {
  overflow-x: scroll;
}

mark {
  background: var(--highlighter-color);
}

blockquote {
  display: grid;
  position: relative;
  padding-left: 2rem;
  padding-right: 1rem;
  margin-bottom: 1rem;
  font-style: italic;
  background: var(--gradient-secondary);
  /* color: var(--gray-12); */

  & blockquote {
    background: transparent;
  }
  
  & p {
    margin-block: 0.5rem;
  }

  & p:only-child {
    margin-block: 0;
  }
}

blockquote::before {
  content: "";
  float: left;
  position: absolute;
  background: var(--gradient-3);
  width: 3px;
  margin-right: 1em;
  top: 0px;
  bottom: 0px;
}

